<template>
  <v-app-bar
    app
    color="white"
    class="ma-2 mt-0 rounded-br-xl rounded-bl-xl pr-2 no-print"
    elevation="1"
  >
    <v-btn icon @click="toggleDrawer">
      <v-app-bar-nav-icon color="secondary" />
    </v-btn>
    <v-spacer />

    <user-menu />
  </v-app-bar>
</template>

<script>
export default {
  components: {
    UserMenu: () => import("@/layouts/components/UserMenu"),
  },
  computed: {
    isLogged() {
      return this.$store.getters["login/isLogged"];
    },
    drawer: {
      set(value) {
        this.$store.commit("settings/setDrawer", value);
      },
      get() {
        return this.$store.state.settings.drawer;
      },
    },
  },

  methods: {
    redirect() {
      if (this.$route.name !== "home") {
        this.$router.push({ path: "/home" });
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style scoped>
div {
  cursor: pointer;
}

.toolbar-container {
  height: 80px;
  width: 120px;
  border-bottom-right-radius: 30px;
}
</style>
